import { Button, Typography, Stack, Divider, Box, Chip, CircularProgress } from '@mui/material'

import { IconBg } from './icons/iconBg'

import { planTypes } from '@/constants/plans/type-plans'

function CheckIcon() {
  return (
    <svg
      fill="none"
      height="25"
      style={{
        minWidth: '24px',
        minHeight: '25px',
      }}
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12.415C0 5.78762 5.37258 0.415039 12 0.415039C18.6274 0.415039 24 5.78762 24 12.415C24 19.0425 18.6274 24.415 12 24.415C5.37258 24.415 0 19.0425 0 12.415Z"
        fill="#DD2590"
      />
      <path
        d="M7.5 12.415L10.5 15.415L16.5 9.41504"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}

const renderDescription = (description, wordsWithLink) => {
  let processedText = description

  wordsWithLink.forEach(({ link, word }) => {
    processedText = processedText.replace(
      word,
      `<a href="${link}" target="_blank" rel="noopener noreferrer" style="color: #DD2590; font-weight: 600;">${word}</a>`,
    )
  })

  return <span dangerouslySetInnerHTML={{ __html: processedText }} />
}

function Plan({ plan, loading }) {
  return (
    <Box
      sx={{
        borderRadius: '16px',
        padding: '1px',
        '&:hover': {
          background: 'linear-gradient(180deg, #F093FB, #F5576C)',
        },
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          padding: '16px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '12px',
          background: '#F9FAFB',
          flex: '1 0 0',
          borderRadius: '16px',
          backdropFilter: 'blur(5px)',
          transition: '0.25s ease-in-out',
          '&:hover': {
            background: '#F2F4F7',
          },
        }}
        variant="outlined"
      >
        <Stack
          alignItems="flex-start"
          className="plan-header"
          flexDirection="column"
          gap="16px"
          justifyContent="center"
          sx={{
            padding: '24px 32px',
            flex: '1 0 0',
          }}
        >
          <Stack
            alignItems="flex-start"
            flexDirection="row"
            gap="12px"
            justifyContent="center"
            sx={{
              position: 'relative',
              padding: '8px',
              ...(plan.price && { borderRadius: '16px', background: '#F2F4F7' }),
            }}
          >
            {plan?.price ? (
              <Chip
                label={plan.price}
                sx={{
                  position: 'absolute',
                  top: '-16px',
                  right: '25px',
                  border: '1px solid #FCCEEE',
                  background: '#EE46BC',
                  padding: '4px 8px',
                  alignItems: 'center',
                  height: '28px',
                  color: '#FDF2FA',
                  textAlign: 'center',
                  fontWeight: 500,
                  lineHeight: '20px',
                }}
                variant="outlined"
              />
            ) : null}
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  width: 33,
                  height: 31,
                  position: 'absolute',
                  left: '0px',
                  bottom: '5px',
                  background: '#EE46BC',
                  filter: 'blur(20px)',
                }}
              />
              <IconBg
                showGreenDot={plan.id === planTypes.organization}
                style={{ width: 65, height: 50, position: 'absolute', left: '-21px', top: '-13.772px' }}
              />
              {plan.icon}
            </Box>
            <Typography
              sx={{
                fontSize: 30,
                fontWeight: 600,
                lineHeight: '38px',
              }}
            >
              {plan.title}
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontSize: 20,
              lineHeight: '30px',
            }}
          >
            {plan.description}
          </Typography>
          <Button
            disabled={plan.disabled}
            startIcon={plan.id === planTypes.teacher_pro && loading ? <CircularProgress size={20} /> : null}
            sx={{ width: '100%' }}
            variant={plan.buttonVariant}
            onClick={() => plan.handleClick(plan)}
          >
            {plan.disabled ? 'Current Plan' : plan.buttonText}
          </Button>
        </Stack>
        <Divider flexItem sx={{ width: '100%' }} />

        <Stack
          alignItems="flex-start"
          flexDirection="column"
          gap="20px"
          sx={{
            padding: '12px 24px',
          }}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
              lineHeight: '30px',
            }}
          >
            Included Features:
          </Typography>
          <Stack alignItems="flex-start" flexDirection="column" gap="12px">
            {plan.features.map(feature => (
              <Stack key={feature.title} alignItems="flex-start" flexDirection="row" gap="12px">
                <CheckIcon />
                <Box>
                  <Typography sx={{ fontSize: 16, lineHeight: '24px', fontWeight: 600, display: 'inline' }}>
                    {feature.title}:
                  </Typography>
                  <Typography sx={{ fontSize: 16, lineHeight: '24px', display: 'inline', marginLeft: '5px' }}>
                    {renderDescription(feature.description, feature.wordsWithLink)}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

export default Plan

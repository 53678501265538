// export const palette = {
// 	type: "light",
// 	primary: {
// 		main: "#F96EA9",
// 		dark: "#de5790",
// 		light: "#F998C1",
// 		contrastText: "#FFFFFF",
// 	},
// 	secondary: {
// 		main: "#042C58",
// 		light: "#AEC6DD",
// 		dark: "#00153D",
// 		contrastText: "#FFFFFF",
// 		status: "rgba(174, 198, 221, 0.10)",
// 		status2: "rgba(174, 198, 221, 0.20)",
// 		states: "rgba(174, 198, 221, 0.30)",
// 	},
// 	error: {
// 		main: "#E51042",
// 		dark: "#C1003F",
// 		light: "#FA282C",
// 		contrastText: "#FFFFFF",
// 	},
// 	warning: {
// 		main: "#FEBE00",
// 		dark: "#FFB000",
// 		light: "#FFEBAF",
// 		contrastText: "#FFFFFF",
// 	},
// 	info: {
// 		main: "#DEEEFF",
// 		dark: "#5C7CA0",
// 		light: "#AEC6DD",
// 		contrastText: "#FFFFFF",
// 	},
// 	success: {
// 		main: "#00C476",
// 		dark: "#009547",
// 		light: "#06CF8F",
// 		contrastText: "#FFFFFF",
// 	},
// 	background: {
// 		default: "#F7FAFE",
// 		light00: "#F7FAFE",
// 		light01: "#F7F9FC",
// 		light02: "#AEC6DD",
// 		light03: "#E1EAF3",
// 	},
// 	detail: {
// 		accent: "#4CD5FF",
// 		blue: "#1B90EE",
// 		sky: "#7BC2FB",
// 		yellow: "#FCF893",
// 		light: "#FEFBBF",
// 		purple: "#A282FF",
// 		mint: "#BAF7CB",
// 	},
// 	grey: {
// 		Grey00: "#E6EAEE",
// 		Grey01: "#F0F5FA",
// 		Grey02: "#E4E4E4",
// 		Grey03: "#D7DADD",
// 		Grey04: "#84868D",
// 		disabled: "#ADB1B5",
// 	},
// 	text: {
// 		primary: "#00153D",
// 		secondary: "#5C7CA0",
// 		tertiary: "#AEC6DD",
// 		disabled: "#B5B5B5",
// 	},
// };

// NEW PALETTE
export const palette = {
	type: "light",
	primary: {
		main: "#F670C7",
		dark: "#C11574",
		light: "#FCCEEE",
		contrastText: "#FFFFFF",
	},
	secondary: {
		main: "#3E4784",
		light: "#4E5BA6",
		dark: "#00153D",
		contrastText: "#FFFFFF",
		status: "rgba(174, 198, 221, 0.10)",
		status2: "rgba(174, 198, 221, 0.20)",
		states: "rgba(174, 198, 221, 0.30)",
	},
	error: {
		main: "#E51042",
		dark: "#C1003F",
		light: "#FA282C",
		contrastText: "#FFFFFF",
	},
	warning: {
		main: "#FEBE00",
		dark: "#FFB000",
		light: "#FFEBAF",
		contrastText: "#FFFFFF",
	},
	info: {
		main: "#DEEEFF",
		dark: "#5C7CA0",
		light: "#AEC6DD",
		contrastText: "#FFFFFF",
	},
	success: {
		main: "#00C476",
		dark: "#009547",
		light: "#06CF8F",
		contrastText: "#FFFFFF",
	},
	background: {
		default: "#F9FAFB",
		light00: "#F7FAFE",
		light01: "#F7F9FC",
		light02: "#AEC6DD",
		light03: "#E1EAF3",
	},
	detail: {
		accent: "#4CD5FF",
		blue: "#1B90EE",
		sky: "#7BC2FB",
		yellow: "#FCF893",
		light: "#FEFBBF",
		purple: "#A282FF",
		mint: "#BAF7CB",
	},
	grey: {
		Grey00: "#E6EAEE",
		Grey01: "#F0F5FA",
		Grey02: "#E4E4E4",
		Grey03: "#D7DADD",
		Grey04: "#84868D",
		disabled: "#ADB1B5",
		grey300: "#D0D5DD",
	},
	text: {
		primary: "#182230",
		secondary: "#344054",
		tertiary: "#475467",
		disabled: "#667085",
	},
};

import { createSvgIcon } from '@mui/material'

export const TeacherProIcon = createSvgIcon(
  <svg fill="none" height={33} viewBox="0 0 32 33" width={32} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.333 13.748v-8m-4 4h8m-8 18.667v-1.6c0-2.24 0-3.36-.436-4.216a4 4 0 0 0-1.748-1.748c-.856-.436-1.976-.436-4.216-.436H9.066c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748c-.436.855-.436 1.976-.436 4.216v1.6m14-18a4.667 4.667 0 1 1-9.333 0 4.667 4.667 0 0 1 9.333 0"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>,
  'TeacherProIcon',
)

import React, { useEffect } from 'react'
import { Dialog, IconButton, DialogContent, Typography, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import SubscriptionPlans from '../../views/SubscriptionPlans'

import { scrollBarStyles } from '@/assets/css/scrollbar'
import { logOpenPricingModal } from '@/lib/firebase_analyics/plg'

function ProductsDialog({ open, onClose }) {
  const handleDialogClose = () => {
    onClose()
  }

  useEffect(() => {
    if (open) {
      _cio.track('view_pricing')
      logOpenPricingModal()
    }
  }, [open])

  return (
    <Dialog fullScreen open={open}>
      <Box
        sx={{
          padding: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: '#F7FAFE',
        }}
      >
        <IconButton aria-label="close" color="inherit" edge="end" size="small" onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          background: '#F7FAFE',
          padding: '25px',
          paddingTop: '20px!important',
          ...scrollBarStyles,
        }}
      >
        <SubscriptionPlans />
      </DialogContent>
    </Dialog>
  )
}

export default ProductsDialog

export function CurrentPlanMessage({ plan, type, endDate }) {
  let message

  if (!plan) {
    message = 'Avail the cool features by selecting a plan that suits your needs.'
  } else if (type === 'institution') {
    message = `Your school has purchased an organization plan. If you're loving
      Speakable send a thank you to the kind soul that procured
      your plan!`
  } else if (type === 'freeTrial') {
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(new Date(endDate))

    message = `Your free trial ends on ${formattedDate}`
  } else if (type === 'stripe') {
    message = `You are currently using a ${plan?.toUpperCase()} plan.`
  } else {
    message = `Avail the cool features by selecting a plan that suits your needs.`
  }

  return <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{message}</Typography>
}

export const buttonText = (plan, selectedPlan) => {
  let text

  if (!plan) {
    text = 'Upgrade'
  } else if (selectedPlan === 'growth') {
    text = 'Start Trial'
  } else {
    if (selectedPlan === 'basic') {
      text = 'Cancel Subscription'
    } else {
      text = 'Upgrade'
    }
  }

  return text
}

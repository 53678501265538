// import React, { useEffect } from "react";
// import { isSafari } from "../utils/browser-type";

import moment from 'moment'

import { myFunctions } from '@/firebase'

/**
 *
 * @returns  {Object} {showChat, hideChat, }

 */
const useChat = () => {
  const loaded = Boolean(window._gs)
  const showChat = () => {}

  const hideChat = () => {
    // removeGoSquared();
  }

  const initChat = (data = {}) => {
    // initializeGoSquared(data);
    loadTranspond(data)
  }

  // eslint-disable-next-line no-unused-vars
  const trackEvent = (event, data = {}) => {
    if (!loaded) return
    // window._gs("event", event, { ...data });
  }

  const sendMicPermissionDeniedMessage = () => {
    if (!loaded) return
  }

  return {
    showChat,
    hideChat,
    initChat,
    trackEvent,
    sendMicPermissionDeniedMessage,
  }
}

export default useChat

//convert to a js function
export const initCIO = (data = {}) => {
  const [firstName = '', lastName = ''] = data?.name?.split?.(' ')
  const identifyObject = {
    email: data?.email,
    id: data?.user_id,
    created_at: data?.created_at,
    first_name: firstName,
    last_name: data?.customData?.userType === 'TEACHER' ? lastName : '',
    ...data?.customData,
  }

  window._cio.identify(identifyObject)

  if (data?.customData?.districtID) {
    const { districtID } = data.customData || {}
    const relationship = {
      id: data?.user_id,
      email: data?.email,
      cio_relationships: {
        action: 'add_relationships',
        relationships: [
          {
            identifiers: {
              object_type_id: '1',
              object_id: districtID,
            },
          },
        ],
      },
    }

    window._cio.identify(relationship)
  }
}

export const getChatObject = (user, profile) => ({
  user_id: user?.uid,
  id: user?.uid,
  name: user?.displayName || profile?.displayName,
  email: user?.email || profile?.email,
  customData: {
    // covert firebase timestamp to seconds
    created_at: profile?.dateMade ? profile?.dateMade?.seconds : null,
    cleverId: profile?.cleverId || null,
    userType: profile?.roles?.includes('SUPER_ADMIN')
      ? 'ADMINISTRATOR'
      : profile?.roles?.includes('ADMIN')
        ? 'TEACHER'
        : 'STUDENT',
    language: profile?.language,
    adminStatus: profile?.adminStatus ? profile?.adminStatus : null,
    assignmentsActivated: profile?.totalAssignmentsActivated || 0,
    quizlet: profile?.quizletProfile || '',
    schoolName: profile?.schoolName || '',
    districtName: profile?.districtName || '',
    districtID: profile?.districtID || '',
    schoolId: profile?.schoolId || '',
    unsubscribed: profile?.roles?.includes('ADMIN')
      ? profile?.unsubscribed
      : profile?.cleverId
        ? true
        : profile?.unsubscribed,
    studentDeviceType: profile?.studentDeviceType || null,
    totalStudentAssignmentsSubmitted: profile?.totalStudentAssignmentsSubmitted || null,
    id: user?.uid,
  },
})

// function initializeGoSquared(data) {
//   // eslint-disable-next-line no-unused-expressions
//   !(function (g, s, q, r, d) {
//     r = g[r] =
//       g[r] ||
//       function () {
//         (r.q = r.q || []).push(arguments);
//       };
//     d = s.createElement(q);
//     d.src = "//d1l6p2sc9645hc.cloudfront.net/gosquared.js";
//     q = s.getElementsByTagName(q)[0];
//     q.parentNode.insertBefore(d, q);
//   })(window, document, "script", "_gs");

//   window._gs("GSN-964062-V");
//   window._gs("set", "anonymizeIP", true);
//   window._gs("set", "trackLocal", true);
//   window._gs("identify", {
//     email: data?.email,
//     name: data?.name,
//     id: data?.id,
//     custom: {
//       ...data?.customData,
//     },
//   });
// }

async function loadTranspond({ email, customData = {}, ...rest }) {
  // dont load if on /activity or /assignment
  const pathname = window.location.pathname

  const objectParty = {
    userId: rest.user_id,
    firstName: rest.name,
    lastName: '',
    cleverId: customData.cleverId,
    userType: customData.userType,
    totalStudentAssignmentsSubmitted: customData.totalStudentAssignmentsSubmitted,
    studentDeviceType: customData.studentDeviceType,
    assignmentsActivated: customData.assignmentsActivated,
    targetLanguage: customData.language,
    email: email,
    district: customData.districtName,
    schoolName: customData.schoolName,
    unsubscribed: customData.unsubscribed ?? false,
    createdAt: customData.created_at,
  }

  await setCapsuleParty(objectParty, rest.user_id)

  if (pathname.includes('/activity') || pathname.includes('/assignment')) {
    return
  }
  let script = document.createElement('script')

  script.defer = true
  script.async = true
  script.type = 'text/javascript'
  script.id = 'mp-loader'
  script.src = 'https://api.transpond.io/tracker?am=MzgwMTg%3D'

  // Add additional properties as global variables
  window.mpEmailAddress = email
  window.mpCustomFields = {
    ...customData,
    createdAt: moment(customData.created_at * 1000).format('YYYY-MM-DD HH:mm:ss'),
  }
  document.head.appendChild(script)
}

export function unloadTranspond() {
  // Remove the script tag
  const script = document.getElementById('mp-loader')

  if (script) {
    script.remove()
  }

  // Remove global variables
  if (window.mpEmailAddress) {
    delete window.mpEmailAddress
  }

  if (window.mpCustomFields) {
    delete window.mpCustomFields
  }

  // You might also want to remove any event listeners or cleanup any other side effects
  // that the Transpond script might have created, if applicable
}

const setCapsuleParty = async (objectParty, userId) => {
  const response = await myFunctions.getCapsulePartyByUserId(userId)

  const data = response.data

  if (data.success && data.party) {
    const capsuleToUpdate = {
      ...objectParty,
      partyId: data.party.id,
    }

    await myFunctions.updateCapsuleParty(capsuleToUpdate).then(console.log).catch(console.log)
  }

  if (data.success && !data.party) {
    await myFunctions.createCapsuleParty({
      email: objectParty.email,
      firstName: objectParty.firstName,
      lastName: objectParty.lastName,
      district: objectParty.district,
      schoolName: objectParty.schoolName,
      userId: objectParty.userId,
    })
  }
}

import * as React from 'react'

export function IconBg({ showGreenDot = false, ...props }) {
  return (
    <svg fill="none" height={61} viewBox="0 0 69 61" width={69} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#a)">
        <path
          d="M4.872 20.89c-.718 1.411-1.007 2.986-.814 4.435.222 1.667 1.074 3.154 2.335 4.079.737.54 1.647.912 2.705 1.108.475.088.95.13 1.415.13 2.076 0 3.991-.847 5.116-2.328 1.356-1.785 1.412-4.041 1.358-6.014-.077-2.877-1.569-4.935-3.989-5.503-3.029-.708-6.597 1.084-8.126 4.093"
          fill="#EE46BC"
        />
      </g>
      <path
        d="M57.947 11.357c.945.771 2.187 1.214 3.59 1.278q.18.008.36.008c2.161 0 4.213-.91 5.55-2.475 1.578-1.851 2-4.528 1.05-6.656C66.992.137 62.5.218 59.94 1.332c-1.866.812-3.27 2.417-3.756 4.293-.548 2.117.16 4.419 1.764 5.732"
        fill="#FEC84B"
      />
      {showGreenDot ? (
        <path
          d="M11.947 59.715c.945.77 2.187 1.213 3.59 1.277q.18.008.36.008c2.161 0 4.213-.909 5.55-2.475 1.578-1.851 2-4.527 1.05-6.655-1.506-3.376-5.997-3.294-8.558-2.18-1.866.811-3.27 2.417-3.756 4.292-.548 2.117.16 4.42 1.764 5.733"
          fill="#47CD89"
        />
      ) : null}
      <path
        d="M65.596 47.52c.034-.387-.002-.8-.105-1.228-.495-2.058-2.379-3.592-4.479-3.65-1.823.014-3.294 1.067-3.848 2.916-.483 1.615.13 3.474 1.492 4.523a4.47 4.47 0 0 0 2.73.911c.648 0 1.3-.135 1.905-.41 1.313-.599 2.196-1.77 2.305-3.062"
        fill="#36BFFA"
      />
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={22}
          id="a"
          width={21}
          x={0}
          y={12.643}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_5646_55923" stdDeviation={2} />
        </filter>
      </defs>
    </svg>
  )
}

import { useState, useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import useUtils from '../../hooks/useUtilities'
import {
  loadProducts,
  createStripeSession,
  createStripeSessionWithExistingSubscription,
  createStripePortalLink,
} from '../../utils/stripe-tools'
import CalendlyDialog from '../../components/CalendlyDialog'
import Plans from '@/components/Plans'
import { planTypes } from '@/constants/plans/type-plans'

const basicPlan = {
  name: 'Basic',
  role: 'basic',
  price: 'Always Free',
  description: 'When you’re getting started and just want the basics.',
  featTitle: "What's Included:",
  features: [
    'Create activities with text, video, and repeat cards',
    'Assign unlimited activities that give students real-time speaking feedback.',
  ],
}

const SubscriptionPlans = () => {
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [openCalendly, setOpenCalendly] = useState(false)
  const { isMobile } = useUtils()

  const { plan, isStripePlan, subscriptionId, type } = useSelector(({ permissions }) => permissions)

  useEffect(() => {
    if (!products?.length) {
      const getProducts = async () => {
        setLoadingProducts(true)
        const result = await loadProducts()
        result.unshift(basicPlan)
        setProducts(result)
        setLoadingProducts(false)
      }

      getProducts()
    }
  }, [])

  const handleStartPlan = async selectedPlan => {
    try {
      let priceId = selectedPlan?.priceId

      if (selectedPlan.id === planTypes.organization) {
        setOpenCalendly(true)
      } else if (selectedPlan.id === planTypes.teacher_pro) {
        setLoading(true)
        await createStripeSession(priceId)
        return
      } else {
        // setLoading(true)
        // if (plan && isStripePlan && subscriptionId && priceId) {
        //   await createStripeSessionWithExistingSubscription(priceId, subscriptionId)
        //   setLoading(false)
        //   return
        // }
        // await createStripeSession(priceId)
        // setLoading(false)
      }
    } catch (e) {
      console.log('handleStartPlan', e)
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <CalendlyDialog
        open={openCalendly}
        onClose={() => setOpenCalendly(false)}
      />
      <Plans
        products={products}
        handleStartPlan={handleStartPlan}
        loading={loading}
      />
    </Fragment>
  )
}

export default SubscriptionPlans

import { createSvgIcon } from '@mui/material'

export const OrgIcon = createSvgIcon(
  <svg
    width={32}
    height={33}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 15.082H6.132c-.747 0-1.12 0-1.406.145-.25.128-.455.332-.582.583-.146.285-.146.658-.146 1.405v11.2m18-13.333h3.867c.747 0 1.12 0 1.405.145.251.128.455.332.583.583.145.285.145.658.145 1.405v11.2m-6 0V8.682c0-1.494 0-2.24-.29-2.81a2.67 2.67 0 0 0-1.166-1.166c-.57-.291-1.317-.291-2.81-.291h-3.467c-1.493 0-2.24 0-2.81.29a2.67 2.67 0 0 0-1.166 1.166c-.29.57-.29 1.317-.29 2.81v19.734m19.333 0H2.666m12-18.667h2.667m-2.667 5.334h2.667m-2.667 5.333h2.667"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'OrgIcon',
)

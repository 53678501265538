/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { useSelector } from 'react-redux'

import { planTypes } from '@/constants/plans/type-plans'

export function useUserPlan() {
  const userPlan = useSelector(({ permissions }) => permissions.plan) as keyof typeof planTypes

  const checkIsTeacherProPlan = (plan: keyof typeof planTypes) =>
    plan === planTypes.teacher_pro || plan === planTypes.starter

  const checkIsOrganizationPlan = (plan: keyof typeof planTypes) =>
    plan === planTypes.organization || plan === planTypes.professional || plan === planTypes.growth

  const checkIsFreePlan = (plan: keyof typeof planTypes) => plan === planTypes.basic || !plan

  return {
    userPlan,
    isFreePlan: checkIsFreePlan(userPlan),
    isTeacherProPlan: checkIsTeacherProPlan(userPlan),
    isOrganizationPlan: checkIsOrganizationPlan(userPlan),
    checkIsFreePlan,
    checkIsTeacherProPlan,
    checkIsOrganizationPlan,
  }
}

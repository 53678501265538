import React from "react";
import { Dialog, DialogTitle, IconButton, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CalendlyDialog = ({ open, onClose }) => {
	const handleDialogClose = () => {
		onClose();
	};

	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth="md">
			<DialogTitle
				sx={{ padding: 1, display: "flex", justifyContent: "flex-end" }}>
				<IconButton
					size="small"
					edge="end"
					color="inherit"
					onClick={handleDialogClose}
					aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<iframe
					title="Calendly"
					src="https://calendly.com/austinmeusch/speakable-demo?month=2023-06"
					width="100%"
					height="600"
					style={{ border: "none" }}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default CalendlyDialog;

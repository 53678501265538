import {
	collection,
	getDocs,
	query,
	where,
	addDoc,
	onSnapshot,
} from "firebase/firestore";
import { db, auth, myFunctions, functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

const createStripeSession = async (priceId, goToStripe = true) => {
	const currentUser = auth.currentUser;
	const docRef = await addDoc(
		collection(db, "io_customers", currentUser.uid, "checkout_sessions"),
		{
			price: priceId,
			allow_promotion_codes: true,
			success_url: `${window.location.origin}/thank-you`,
			cancel_url: window.location.origin,
			client_reference_id: getClientReferenceId(),
		}
	);
	// Wait for the CheckoutSession to get attached by the extension
	onSnapshot(docRef, (snap) => {
		const { error, url } = snap.data();
		if (!goToStripe) {
			console.log("Checkout Session Created upon login", snap.data());
			return;
		}
		if (error) {
			// Show an error to your customer and
			// inspect your Cloud Function logs in the Firebase console.
			alert(`An error occured: ${error.message}`);
		}
		if (url) {
			// We have a Stripe Checkout URL, let's redirect.
			window.location.assign(url);
		}
	});
};

const loadProducts = async () => {
	try {
		const productData = await getDocs(
			query(collection(db, "io_products"), where("active", "==", true))
		);
		const products = productData.docs.map((p) => {
			return { ...p.data(), id: p.id };
		});

		const pricePromises = products.map((product) => {
			return getDocs(collection(db, "io_products", product.id, "prices"));
		});
		const prices = await Promise.all(pricePromises);
		const pricesData = prices.map((price) => {
			return price.docs.map((p) => {
				return { ...p.data(), id: p.id };
			});
		});

		const productsWithPrices = products.map((product, index) => {
			return { ...product, prices: pricesData[index] };
		});
		return productsWithPrices;
	} catch (error) {
		console.log(error);
		return { error: true, message: error.message };
	}
};

const getCurrentUserSubscriptions = async () => {
	try {
		const currentUser = auth.currentUser;
		const docRef = await getDocs(
			query(
				collection(db, "io_customers", currentUser.uid, "subscriptions"),
				where("status", "in", ["trialing", "active"])
			)
		);
		const subscriptions = docRef.docs.map((s) => {
			return { ...s.data(), id: s.id };
		});
		return subscriptions;
	} catch (error) {
		console.log(error);
		return { error: true, message: error.message };
	}
};

const createStripeSessionWithExistingSubscription = async (
	priceId,
	subscriptionId
) => {
	try {
		const { data } = await myFunctions.createStripeCustomerPortal({
			returnUrl: window.location.origin,
			locale: "auto", // Optional, defaults to "auto"
			// configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
		});

		window.location.assign(
			`${data.url}`
			// `${data.url}/subscriptions/${subscriptionId}/preview/${priceId}` // Sometime would not work
			// `${data.url}/subscriptions/${subscriptionId}/update`
		);
	} catch (error) {
		console.log(error);
	}
};

const createStripePortalLink = async () => {
	try {
		const { data } = await myFunctions.createStripeCustomerPortal({
			returnUrl: window.location.origin,
			locale: "auto", // Optional, defaults to "auto"
			// configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
		});

		window.location.assign(data.url);
	} catch (error) {
		console.log(error);
	}
};

const getClientReferenceId = () => {
	return (
		(window.Rewardful && window.Rewardful.referral) ||
		"checkout_" + new Date().getTime()
	);
};

const initializeCustomer = async ({ name, email, metadata = {} }) => {
	const createCustomer = httpsCallable(functions, "createStripeCustomer");
	try {
		const { data } = await createCustomer({ name, email, metadata });
		console.log("Stripe Customer created: ", data);
		return data;
	} catch (error) {
		console.log(error);
	}
};

export {
	createStripeSession,
	loadProducts,
	getCurrentUserSubscriptions,
	createStripeSessionWithExistingSubscription,
	createStripePortalLink,
	initializeCustomer,
	getClientReferenceId,
};

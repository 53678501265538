import { Typography, Stack, Grid } from '@mui/material'

import { OrgIcon } from './icons/organizaiton'
import { TeacherProIcon } from './icons/teacherPro'
import Plan from './Plan'

import { planTypes } from '@/constants/plans/type-plans'
import { useUserPlan } from '@/hooks/useUserPlan'

function Plans({ products, handleStartPlan, loading }) {
  const { isTeacherProPlan, isOrganizationPlan } = useUserPlan()
  const teacherProPlan = products.find(product => product.role === planTypes.teacher_pro)
  const teacherProPlanPrice = teacherProPlan?.prices[0]
  const teacherProPlanPriceFormatted = teacherProPlanPrice
    ? `$${(teacherProPlanPrice.unit_amount / 100).toFixed()}/year`
    : null

  const pricingPlans = [
    {
      id: planTypes.teacher_pro,
      title: 'Teacher Pro',
      description: 'Get access to basic auto-grading features and collaboration tools.',
      price: teacherProPlanPriceFormatted,
      priceId: teacherProPlanPrice?.id,
      icon: <TeacherProIcon sx={{ width: 32, height: 32, zIndex: 1, position: 'relative' }} />,
      features: [
        {
          title: 'Auto-Grading',
          description: 'Simplified options including pass/fail for free responses.',
          wordsWithLink: [],
        },
        {
          title: 'Instant Feedback',
          description: 'Immediate explanations for pass/fail grading.',
          wordsWithLink: [
            {
              link: 'https://www.speakable.io/features/pass-fail',
              word: 'pass/fail',
            },
          ],
        },
        {
          title: 'Assessment Options',
          description: 'Practice mode only.',
          wordsWithLink: [],
        },
        {
          title: 'Progress Tracking',
          description: 'Limited to assignment results.',
          wordsWithLink: [],
        },
        {
          title: 'Integrations',
          description: 'Content integrations, SSO, and roster syncing with Google Classroom and Clever.',
          wordsWithLink: [],
        },
        {
          title: 'Collaboration',
          description: 'Create & join spaces, access global library.',
          wordsWithLink: [
            {
              link: 'https://www.speakable.io/collaboration',
              word: 'spaces',
            },
          ],
        },
      ],
      buttonText: 'Upgrade Now',
      buttonVariant: 'contained',
      handleClick: handleStartPlan,
      disabled: isTeacherProPlan,
    },
    {
      id: planTypes.organization,
      title: 'Organization',
      description: 'Advanced auto-grading, detailed feedback, comprehensive analytics, and more.',
      price: null,
      priceId: null,
      icon: <OrgIcon sx={{ width: 32, height: 32, zIndex: 1, position: 'relative' }} />,
      features: [
        {
          title: 'Auto-Grading',
          description: 'Comprehensive, including custom rubrics',
          wordsWithLink: [
            {
              link: 'https://www.speakable.io/features/rubrics',
              word: 'custom rubrics',
            },
          ],
        },
        {
          title: 'Instant Feedback',
          description: 'Advanced options including grammar insights and proficiency level estimation.',
          wordsWithLink: [
            {
              link: 'https://www.speakable.io/features/grammar-insights',
              word: 'grammar insights',
            },
            {
              link: 'https://www.speakable.io/features/proficiency-level-estimation',
              word: 'proficiency level estimation',
            },
          ],
        },
        {
          title: 'Assessment Options',
          description: 'Customizable modes with post-submission feedback.',
          wordsWithLink: [],
        },
        {
          title: 'Progress Tracking',
          description: 'Analytics at all levels',
          wordsWithLink: [
            {
              link: 'https://www.speakable.io/progress-tracking',
              word: 'Analytics',
            },
          ],
        },
        {
          title: 'Integrations',
          description:
            'Content integrations, SSO, roster syncing, and grade passback with platforms like Canvas, Google Classroom, and more.',
          wordsWithLink: [
            {
              link: 'https://www.speakable.io/integrations',
              word: 'more',
            },
          ],
        },
        {
          title: 'Collaboration',
          description: 'Full access to create & join spaces, global & organization-specific libraries.',
          wordsWithLink: [
            {
              link: 'https://www.speakable.io/collaboration',
              word: 'spaces',
            },
          ],
        },
        {
          title: 'Admin Controls',
          description: 'Manage users and track analytics.',
          wordsWithLink: [],
        },
      ],
      buttonText: 'Talk to Sales',
      buttonVariant: 'contained',
      handleClick: () => window.open('https://www.speakable.io/contact'),
      disabled: isOrganizationPlan,
    },
  ]

  return (
    <Stack
      className="main-container"
      flexDirection="column"
      gap="16px"
      sx={{
        padding: '24px 32px',
      }}
    >
      <Stack
        alignItems="center"
        flexDirection="column"
        gap="20px"
        sx={{
          paddingBottom: '96px',
        }}
      >
        <Stack
          alignItems="flex-start"
          flexDirection="column"
          gap="32px"
          sx={{
            maxWidth: '1280px',
            padding: '0px 32px',
          }}
        >
          <Stack
            alignItems="center"
            flexDirection="column"
            gap="10px"
            sx={{
              padding: '0px 64px',
            }}
          >
            <Typography
              sx={{
                color: '#DD2590',
                textAlign: 'center',
                fontSize: 18.416,
                lineHeight: '24.554px',
                letterSpacing: '-0.153px',
              }}
            >
              Pricing
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: 60,
                fontWeight: 500,
                lineHeight: '72px',
                letterSpacing: '-1.2px',
              }}
            >
              Start for free and{' '}
              <span
                style={{
                  background: 'linear-gradient(45deg, #F093FB, #F5576C)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                }}
              >
                upgrade
              </span>
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: 24,
                lineHeight: '32px',
              }}
            >
              to unlock more features.
            </Typography>
          </Stack>
        </Stack>
        <Grid container maxWidth="lg" spacing={4}>
          {pricingPlans.map(plan => (
            <Grid key={plan.id} item lg={6} sm={12}>
              <Plan loading={loading} plan={plan} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  )
}

export default Plans
